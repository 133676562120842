body{
    margin: 0;
    padding: 0;
    font-family:'Poppins',sans-serif;
    box-sizing: border-box;
}
p,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-family:'Poppins',sans-serif;
}
 
.card-conrainer{
    width: 100%;
    max-width: 1300px;
    margin: auto;
}
.card-conrainer .card-row{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.card-conrainer .card-row .card-col{
    width: 33.33%;
    flex: 0 1 33.33%;
    padding: 8px 8px;
    box-sizing: border-box;
}
.card-conrainer .card-row .card-col .relation-cards{
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.06);
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.06);
    border-radius: 16px;
    padding: 20px 16px;
    box-sizing: border-box;
}
.card-conrainer .card-row .card-col .relation-cards .name-part{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .left{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .left .icon{
    width: 36px;
    height: 36px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    border-radius: 50%;
    margin-right: 10px;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .left h5{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #231F20;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .left p{
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #999999;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .pending{
    background: rgba(229, 193, 0, 0.12);
    padding: 10px;
    border-radius: 4px;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .pending p{
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #E5C100;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .verified{
    background: rgba(0, 181, 137, 0.1);
    padding: 10px;
    /* height: 17px; */
    border-radius: 4px;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .verified p{
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #00B589;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .reject{
    background: rgba(241, 91, 77, 0.1);
    padding: 10px;
    /* height: 17px; */
    display: flex;
    border-radius: 4px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.card-conrainer .card-row .card-col .relation-cards .name-part .reject p{
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #F15B4D;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part{
    width: 95%;
    height: auto;
    position: relative;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part h4{
    position: relative;
    font-weight: 500;
    color: #231F20;
    font-size: 14px;
    margin-bottom: 10px;
  
}
.card-conrainer .card-row .card-col .relation-cards .relation-part h4:before{
    content:'';
    position: absolute;
    width: 50%;
    height: 0.5px;
    background: #e2e2e2;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
 
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part.disable{
    opacity: 0.5;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part .rdo-btn{
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part .rdo-btn .checkmark{
    background: #fff;
    border: 1px solid #9E9E9E;
    padding: 10px 12px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    white-space: nowrap;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part .rdo-btn input{
    display: none;
}
.card-conrainer .card-row .card-col .relation-cards .relation-part .btn-part .rdo-btn input:checked ~ .checkmark {
    background: #5367FC;
    border-color: #5367FC;
    color: #fff;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part{
    width: 100%;
    height: auto;
    position: relative;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 16px;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part .check-box{
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #231F20;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part input{
    display: none;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part .checkmark{
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #9E9E9E;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part input:checked ~ .checkmark{
    background: #5367FC;
}
.card-conrainer .card-row .card-col .relation-cards .remove-part input:checked ~ .checkmark:after {
  content: '';
  position: absolute;
      left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.card-conrainer .card-row .card-col .relation-cards .pan-dob-part{
    width: 100%;
    height: auto;
    position: relative;
    padding:16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #D8D8D8;
    margin-bottom: 16px; */
}
.card-conrainer .card-row .card-col .relation-cards .pan-dob-part .pan-box{
    display: flex;
    align-items: center;
}
.card-conrainer .card-row .card-col .relation-cards .pan-dob-part .pan-box .icon{
    margin-right: 10px;
}
.card-conrainer .card-row .card-col .relation-cards .pan-dob-part .pan-box h5{
    color: #999999;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}
.card-conrainer .card-row .card-col .relation-cards .pan-dob-part .pan-box p{
    color: #040408;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}
.card-conrainer .card-row .card-col .relation-cards .para{
    font-size: 12px;
    color: #231F20;
    line-height: 16px;
    margin-bottom: 16px;
    padding-top: 10px;
    border-top:1px solid #eee;
    margin-top:10px;
}
.card-conrainer .card-row .card-col .relation-cards .button-part{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
}
.card-conrainer .card-row .card-col .relation-cards .button-part .btn{
    width: 100%;
    padding: 12px 12px;
    border-radius: 8px;
    background: #5367FC;
    color: #fff;
    font-size: 14px;
    border: 1px solid #5367FC;
    cursor: pointer;
}
.card-conrainer .card-row .card-col .relation-cards .button-part .btn.btn-border{
    background: transparent;
    color: #231F20;
}
a.btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    /* background-color: #0a3294;
    color: white; */
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
a.default-link {
    color: inherit;      /* Inherit color from parent element */
    text-decoration: none; /* Remove the underline */
}
.text-field {
    border-radius: 12px; /* Border radius set karte hain */
    padding: 10px; /* Padding set karte hain */
    border: 1px solid #ccc; /* Border color */
    outline: none; /* Outline remove karte hain */
    /* font-size: 1px; */
    width: 300px;
    margin-top:10px;
    color:'#999999';
  }
  
  .container1 {
    display: flex;
    justify-content: space-between; /* Boxes ke beech mein barabar space */
    align-items: center; /* Vertical alignment */
    gap:2px; /* Gap between boxes */
    margin-top: 10px;
  }
  
  .box {
    border: 1px solid #999999; /* Border sabhi elements ke liye */
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Text ko center align karna */
    /* font-size: 16px; */
    padding:7px;
  }
  
  .family {
    border-color:'#999999'; /* Family box ka border red */
  }
  
  
  
  

a.default-link:hover {color:#1d76ce;;
    cursor: pointer; /* Show pointer cursor on hover */
}

/* Optional: Adjust the color based on your theme */
a.default-link {
    color: #0a78e7; /* Set the link color (blue by default) */
}


 
@media only screen and (max-width: 600px) {
 .card-conrainer .card-row{
    display: block;
 }
 .card-conrainer .card-row .card-col{
    width: 100%;
 }
}
@media only screen and (max-width: 1024px) and (min-width: 601px) {
    .card-conrainer .card-row .card-col{
        width:50%;
        flex:0 1 50%;
    }
}